import styled from 'styled-components';

export const PageContainer = styled.div`
    max-width: 800px;
    padding: 25px 50px 50px 50px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media (max-width: ${props => props.theme.screen.medium}) {
        padding: 10px 20px;
    }
`;

export const Page = styled.div`
    width: 100%;
    padding-bottom: 160px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 90vw;
        padding-bottom: 80px;
    }
`;
